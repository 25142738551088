var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExhibitionServices"},[_c('div',{staticClass:"navs"},[_c('Carousel',{attrs:{"show":"1"}}),_c('div',{staticClass:"EditionCenter"},[_c('div',{staticClass:"navcon"},[_c('p',{staticClass:"navconleft"},[_c('span',{style:({
              background: _vm.show == 'FoodandBeverages' ? '#197CC3' : '#fff',
              color: _vm.show == 'FoodandBeverages' ? '#fff' : '#000',
              zIndex: _vm.show == 'FoodandBeverages' ? '5' : '',
            }),on:{"click":function($event){return _vm.gopage('FoodandBeverages', $event)}}},[_vm._v(" "+_vm._s(_vm.$t("language.FoodAndBeverages"))+" "),_c('b',{staticStyle:{"font-weight":"normal"}},[_vm._v("|")])]),_c('span',{style:({
              background:
                _vm.show == 'ExhibitionEquipmentRental' ? '#197CC3' : '#fff',
              color: _vm.show == 'ExhibitionEquipmentRental' ? '#fff' : '#000',
              zIndex: _vm.show == 'ExhibitionEquipmentRental' ? '5' : '',
            }),on:{"click":function($event){return _vm.gopage('ExhibitionEquipmentRental', $event)}}},[_vm._v(" "+_vm._s(_vm.$t("language.ExhibitionEquipmentRental"))+" "),_c('b',{staticStyle:{"font-weight":"normal"}},[_vm._v("|")])]),_c('span',{style:({
              background: _vm.show == 'AdvertisingServices' ? '#197CC3' : '#fff',
              color: _vm.show == 'AdvertisingServices' ? '#fff' : '#000',
              zIndex: _vm.show == 'AdvertisingServices' ? '5' : '',
            }),on:{"click":function($event){return _vm.gopage('AdvertisingServices', $event)}}},[_vm._v(" "+_vm._s(_vm.$t("language.AdvertisingServices"))+" "),_c('b',{staticStyle:{"font-weight":"normal"}},[_vm._v("|")])]),_c('span',{style:({
              background: _vm.show == 'BuildService' ? '#197CC3' : '#fff',
              color: _vm.show == 'BuildService' ? '#fff' : '#000',
              zIndex: _vm.show == 'BuildService' ? '5' : '',
            }),on:{"click":function($event){return _vm.gopage('BuildService', $event)}}},[_vm._v(" "+_vm._s(_vm.$t("language.BuildService"))+" ")])]),_c('p',{staticClass:"navconright"},[_c('van-icon',{attrs:{"color":"#999999","name":"wap-home","size":"20"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("language.current"))+" "),_vm._v(" : "+_vm._s(_vm.$t("language.HOMEpage"))+" "),_vm._v(" > "),_c('span',{staticClass:"spans",domProps:{"innerHTML":_vm._s(_vm.$t('language.ExhibitionServices'))}}),_vm._v(" > "+_vm._s(_vm.$t(("language." + _vm.show)))+" ")])],1)]),(_vm.show == 'FoodandBeverages')?_c('div',{staticClass:"content"},[_c('FoodandBeverages')],1):(_vm.show == 'ExhibitionEquipmentRental')?_c('div',{staticClass:"content"},[_c('ExhibitionEquipmentRental')],1):(_vm.show == 'AdvertisingServices')?_c('div',{staticClass:"content"},[_c('AdvertisingServices')],1):(_vm.show == 'BuildService')?_c('div',{staticClass:"content"},[_c('BuildService')],1):_vm._e()]),_c('Bottom')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }