<!--展会服务-->
<template>
  <div class="ExhibitionServices">
    <div class="navs">
      <Carousel show="1" />
      <div class="EditionCenter">
        <div class="navcon">
          <p class="navconleft">
            <span
              :style="{
                background: show == 'FoodandBeverages' ? '#197CC3' : '#fff',
                color: show == 'FoodandBeverages' ? '#fff' : '#000',
                zIndex: show == 'FoodandBeverages' ? '5' : '',
              }"
              @click="gopage('FoodandBeverages', $event)"
            >
              {{ $t("language.FoodAndBeverages") }}
              <!-- 餐饮服务 -->
              <b style="font-weight: normal">|</b>
            </span>
            <span
              :style="{
                background:
                  show == 'ExhibitionEquipmentRental' ? '#197CC3' : '#fff',
                color: show == 'ExhibitionEquipmentRental' ? '#fff' : '#000',
                zIndex: show == 'ExhibitionEquipmentRental' ? '5' : '',
              }"
              @click="gopage('ExhibitionEquipmentRental', $event)"
            >
              {{ $t("language.ExhibitionEquipmentRental") }}
              <!-- 展具租赁 -->
              <b style="font-weight: normal">|</b>
            </span>
            <span
              :style="{
                background: show == 'AdvertisingServices' ? '#197CC3' : '#fff',
                color: show == 'AdvertisingServices' ? '#fff' : '#000',
                zIndex: show == 'AdvertisingServices' ? '5' : '',
              }"
              @click="gopage('AdvertisingServices', $event)"
            >
              {{ $t("language.AdvertisingServices") }}
              <!-- 广告服务 -->
              <b style="font-weight: normal">|</b>
            </span>
            <span
              :style="{
                background: show == 'BuildService' ? '#197CC3' : '#fff',
                color: show == 'BuildService' ? '#fff' : '#000',
                zIndex: show == 'BuildService' ? '5' : '',
              }"
              @click="gopage('BuildService', $event)"
            >
              {{ $t("language.BuildService") }}
              <!-- 搭建服务 -->
            </span>
          </p>
          <p class="navconright">
            <van-icon color="#999999" name="wap-home" size="20" />
            <span>
              {{ $t("language.current") }}
              <!-- 当前位置 -->
              :
              {{ $t("language.HOMEpage") }}
              <!-- 首页 -->
              >
              <span
                class="spans"
                v-html="$t('language.ExhibitionServices')"
              ></span>
              <!-- 展会服务 -->
              >
              {{ $t(`language.${show}`) }}
              <!-- 餐饮服务 -->
            </span>
          </p>
        </div>
        <div class="content" v-if="show == 'FoodandBeverages'">
          <FoodandBeverages />
        </div>
        <div class="content" v-else-if="show == 'ExhibitionEquipmentRental'">
          <ExhibitionEquipmentRental />
        </div>
        <div class="content" v-else-if="show == 'AdvertisingServices'">
          <AdvertisingServices />
        </div>
        <div class="content" v-else-if="show == 'BuildService'">
          <BuildService />
        </div>
      </div>
      <Bottom />
    </div>
  </div>
</template>

<script>
import Carousel from "../../components/pc-carousel.vue";
import Bottom from "../../components/bottom.vue";
import FoodandBeverages from "../../components/pcExhibitionServices/FoodandBeverages.vue";
import ExhibitionEquipmentRental from "../../components/pcExhibitionServices/ExhibitionEquipmentRental.vue";
import AdvertisingServices from "../../components/pcExhibitionServices/AdvertisingServices.vue";
import BuildService from "../../components/pcExhibitionServices/BuildService.vue";
export default {
  components: {
    Carousel,
    Bottom,
    FoodandBeverages,
    ExhibitionEquipmentRental,
    AdvertisingServices,
    BuildService,
  },
  data() {
    return {
      show: "",
    };
  },
  created() {
    this.showcomponent();
  },
  mounted() {
    let traffic = document.getElementsByClassName("ExhibitionServices")[0];
    this.$nextTick(() => {
      setTimeout(() => {
        let targetbox = document.getElementsByClassName("navcon")[0];
        traffic.scrollTop = targetbox.offsetTop;
      });
    });
  },
  methods: {
    showcomponent() {
      if (this.$route.query.path == "餐饮服务") {
        this.show = "FoodandBeverages";
      } else if (this.$route.query.path == "展具租赁") {
        this.show = "ExhibitionEquipmentRental";
      } else if (this.$route.query.path == "广告服务") {
        this.show = "AdvertisingServices";
      } else if (this.$route.query.path == "搭建服务") {
        this.show = "BuildService";
      } else if (this.$route.query.path == "/") {
        this.show = "FoodandBeverages";
      } else if (this.$route.query.path == "") {
        this.show = "FoodandBeverages";
      }
    },
    gopage(val, e) {
      if (val == "FoodandBeverages") {
        this.$router.replace({
          path: "/ExhibitionServices",
          query: { path: `餐饮服务` },
        });
      } else if (val == "ExhibitionEquipmentRental") {
        this.$router.replace({
          path: "/ExhibitionServices",
          query: { path: `展具租赁` },
        });
      } else if (val == "AdvertisingServices") {
        this.$router.replace({
          path: "/ExhibitionServices",
          query: { path: `广告服务` },
        });
      } else if (val == "BuildService") {
        this.$router.replace({
          path: "/ExhibitionServices",
          query: { path: `搭建服务` },
        });
      }
      this.show = val;
      e.target.style.backgroundColor = "#197CC3";
      e.target.style.color = "#fff";
    },
  },
};
</script>

<style scoped>
.ExhibitionServices::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.navs {
  width: 100%;
  height: 100%;
}
a {
  color: black;
}
.ExhibitionServices {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.EditionCenter {
  width: 1400px;
  margin: 0 auto;
  margin-bottom: 200px;
}
.navcon {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}
.navconleft span {
  display: inline-block;
  padding: 0px 20px;
  line-height: 70px;
  text-align: center;
}
.navconleft :hover {
  background-color: #1a7cc3 !important;
  color: white !important;
  cursor: pointer;
  z-index: 5 !important;
}
.navconleft :hover b {
  display: none !important;
}
.navconleft span {
  position: relative;
}
.navconleft b {
  position: absolute;
  z-index: 2 !important;
  right: -3px;
}
.navconright {
  line-height: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navconright > span {
  display: inline-block;
}
/* .content{
        padding: 20px;
        background-color: #FAFAFA;
        margin-bottom: 30px;
        margin-top: 10px;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
        margin-top: 50px
    } */
</style>