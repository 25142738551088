<!--搭建服务-->
<template>
<div>
    <h3>会展中心为您提供标准化展位搭建和展具租赁服务</h3>
            <div class="content">
                <div v-for="(item,index) in contents" :key="index">
                    <img :src="item.imgs" alt="">
                    <p class="contenttext">{{$t(item.text)}}</p>
                </div>      
            </div>
            <!-- 标摊详细介绍 -->
            <!-- <h2>
                {{$t('language.boothdetailedintroduction')}}
            </h2>
            <div class="detail">
                <img src="../../assets/image/标摊详细介绍.png" alt="">
            </div> -->
        </div>
</template>

<script>
export default {
data(){
    return{
        contents:[
            {
            text:"language.StandardBooth",
            // text:"标准展位（标摊）",
            imgs:require('../../assets/image/标准展位（标摊）.png'),
            },
            // {
            // text:"language.Standardboothisintroduced",
            // // text:"标摊介绍",
            // imgs:require('../../assets/image/标摊介绍.png'),
            // },
            {
            text:"language.specialbooth",
            // text:"特装展位",
            imgs:require('../../assets/image/特装展位.png'),
            }
            
        ],
        height:"calc(100% - 70px)"
    }
},
};
</script>

<style scoped>
h3{
    font-size: 30px;
    line-height: 40px;
    margin-top: 40px
}
    .content{
        padding: 20px;
        background-color: #FAFAFA;
        margin-bottom: 30px;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
        margin-top:20px;
        display: flex;
        justify-content: space-around
    }
    .contenttext{
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top: 10px;
        text-align: center
    }
    .content img{
        width: 413px;
        border: 1px dashed black;
    }
    /* h2{
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height:30px;
        margin-top: 100px;
        text-align: center
    }
    .detail{
        width: 930px;
        margin: 0 auto;
        margin-top: 30px
    }
     .detail img{
         width: 100%;
     } */
</style>