<!--餐饮服务-->
<template>
<div>
            <div class="content">
                <div class="contenttop">
                    <p class="contenttitle">{{ $t(contents[0].title) }}</p>
                    <p class="contenttext">{{$t(contents[0].text)}}</p>
                    <img :src="contents[0].imgs" alt="">
                </div>
                <div class="contentbottom">
                    <div class="left">
                    <p class="contenttitle">{{ $t(contents[1].title) }}</p>
                    <p class="contenttext">{{$t(contents[1].text)}}</p>
                    <img :src="contents[1].imgs" alt="">
                    </div>
                    <div class="right">
                    <p class="contenttitle">{{ $t(contents[2].title) }}</p>
                    <p class="contenttext">{{$t(contents[2].text)}}</p>
                    <img :src="contents[2].imgs" alt="">
                    </div>
                </div>
            </div>
</div>
</template>

<script>
export default {
data(){
    return{
        contents:[
            {
            title:"language.foodandbeverage",
            // title:"配套餐饮",
            text:"language.metersandtheauxiliary",
            // text:"3000平方米中央厨房、2500平方米的配套厨房可为参展商、会议等人员提供多种形式的美食享受",
            imgs:require('../../assets/image/新中式.png'),
            },
            {
            title:"language.Exhibitioncatering",
            // title:"会展餐饮",
            text:"language.forexhibitiongueststo",
            // text:"会展配套餐饮，为展会客人提供便捷、特色餐饮服务",
            imgs:require('../../assets/image/自助午餐.png'),
            },
            {
            title:"language.banquetcatering",
            // title:"宴会餐饮",
            text:"language.cateringprovidyouwith",
            // text:"豪华宴会餐饮，为您提供鲜美饕餮美食，尊享极致餐饮服务",
            imgs:require('../../assets/image/龙虾.png'),
            }
            
        ],
        height:"calc(100% - 70px)"
    }
},
};
</script>

<style scoped>
    .content{
        padding-top:30px;
        padding-bottom:30px;
        text-align: center;
        background-color: #FAFAFA;
        margin-bottom: 30px;
        margin-top: 10px;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
        margin-top: 50px
    }
    .contenttitle{
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 40px;
    }
    .contenttext{
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
    }
    .contenttop img{
        width: 1300px;
    }
    .contentbottom{
        width: 1400px;
        margin-top: 50px;
        display: flex;
        justify-content: space-around;
    }
    .contentbottom img{
        width: 600px;
        height: 300px;
    }
</style>